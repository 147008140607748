import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../utils/seo"
import Search from "../components/search/search"
import CMS from "../utils/cms"
//import Heading from "../utils/heading"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
//import Hero from "../components/hero/hero"

const Wrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin: 1rem auto 0 auto;

  @media ${props => props.theme.mq.sm} {
    margin: 1.5rem auto 0 auto;
  }

  @media ${props => props.theme.mq.md} {
    margin: 0 auto;
  }

  .title{
    ${props => props.theme.fonts.h3}
  }
  
`

const LandingPage = ({ data }) => {

  const pageTitle = data.wpLandingPage.title;


  //show results on landing page
  function showResults() {

    const landingCat = data.wpLandingPage.landingPageCategories?.nodes[0]?.name

    if (landingCat !== "Locations") {
      return <Wrapper><Search showResults={true} filters={prefilter()} /></Wrapper>
    }

  }

  function prefilter() {
    if (pageTitle.toLowerCase() === "properties") {
      return undefined;
    }
    const filterGroup = data.wpLandingPage.landing_page_acfs.filters
    const filterState = {
      where: { id: (filterGroup.whereTown !== "999" ? filterGroup.whereTown : undefined) },
      who_adults: filterGroup.capacity > 0 ? filterGroup.capacity : 1,
      who_pets: 0,
      what: [],
      deal: filterGroup.onlyOffers ? { type: 'SpecificDates' } : undefined
    }

    var filterMap = filterState

    if (filterGroup.attributes && filterGroup.attributes.length) {
      filterMap = filterGroup.attributes.reduce((allFilters, attr) => {
        if (attr.attrField === 'pets')
          allFilters.who_pets = 1
        else
          // allFilters.what[attr.attrField] = attr.attrValue
          allFilters.what.push({ title: 'fixed', id: attr.attrField, value: attr.attrValue, hidden: true, readOnly: true });
        return allFilters
      }, filterState)
    }

    return filterMap
  }

  return (

    <>
      <SEO
        title={data.wpLandingPage.seo.title || data.wpLandingPage.title}
        description={
          data.wpLandingPage.seo.metaDesc ||
          data.wpLandingPage.excerpt ||
          data.wpLandingPage.description
        }
        image={data?.wpLandingPage?.featuredImage?.node.uri}
        canonical={data.wpLandingPage.uri}
        article={false}
      />

      {/* <Wrapper>

        <Heading type="title" as="h2" color={props => props.theme.colors.lightBlue}>
          {data.wpLandingPage.title}
        </Heading>

      </Wrapper> */}

      {/* {data.wpLandingPage.featuredImage &&
        <Hero image={data.wpLandingPage.featuredImage.node.localFile.childImageSharp.fluid} />
      } */}

      <Breadcrumbs currentPage={data.wpLandingPage.title} />

      {data.wpLandingPage.content &&
        <Wrapper>
          <div dangerouslySetInnerHTML={{ __html: data.wpLandingPage.content }} />
        </Wrapper>
      }

      <CMS>{data.wpLandingPage.content_module_system.modules}</CMS>

      {showResults()}

    </>

  )
}

export default LandingPage

export const pageQuery = graphql`
  query($id: String!, $wpId: Int!) {
    wpLandingPage(id: { eq: $id }, databaseId: {eq: $wpId}) {
      id
      databaseId
      slug
      uri
      title
      content
      seo {
        title
        metaDesc
      }
      featuredImage {
        node {
          uri
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      content_module_system {
        modules {
          ...headerBlockFragmentsLandingPage
          ...contentFragmentsLandingPage
          ...sideBySideFragmentsLandingPage
          ...navigationSliderFragmentsLandingPage
          ...propSelectFragmentsLandingPage
        }
      }
      landing_page_acfs {
        filters {
          ...filterFragments
        }
      }
      landingPageCategories {
        nodes {
          name
        }
      }
    }
  }
`
