import React from "react";
import styled from "styled-components";

import { globalHistory } from "@reach/router";

const Wrapper = styled.div.attrs({
  className: "breadcrumbsWrapper",
})`
  display: none;
  @media ${props => props.theme.mq.md} {
    display: block;
  }
`;
const Inner = styled.div.attrs({
  className: "breadcrumbsInner",
})`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  padding: 1rem 0;
`;
const Breadcrumb = styled.ol`
  margin:0;
  padding:0;
  li {
    display: inline-block;
    margin-right: 0.5rem;
    &:not(:last-of-type) {
      &:after {
        margin-left: 0.5rem;
        content: " / ";
        color: ${props => props.theme.colors.secondary};
      }
    }

    &:last-of-type {
      a {
        pointer-events: none;
      }
    }

    a {
      color: ${props => props.theme.colors.secondary};
      text-decoration: none;
    }
  }
`;

const Breadcrumbs = ({ currentPage }) => {
  //var url = window.location.href

  const startUrl = globalHistory.location.pathname;
  const url = startUrl.slice(0, -1)

  var pageTitle = currentPage.replace('amp;', '').replace('#038;', '');

  var delimiter = "/";
  var start = 1;
  var tokens = url.split(delimiter).slice(start);
  var result = tokens.join(delimiter);
  var breadcrumbs = result.split("/");

  function URLgen(breadcrumbs, i) {
    for (var url = "", cur = 0; cur <= i; cur++) {
      url += "/" + breadcrumbs[cur];
    }

    return url;
  }



  function prettify(breadcrumb) {
    return breadcrumb
      .split("-")
      .map(function capitalize(part) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      })
      .join(" ");
  }

  return (
    <Wrapper>
      <Inner>
        <Breadcrumb
          itemScope={true}
          itemType="https://schema.org/BreadcrumbList"
          className="breadcrumbs-container"
        >
          <li
            itemProp="itemListElement"
            itemScope={true}
            itemType="https://schema.org/ListItem"
          >
            <a
              href="/"
              itemScope={true}
              itemType="https://schema.org/Thing"
              itemProp="item"
              itemID="Home"
              title="Home"
            >
              <span itemProp="name">Home</span>
            </a>
            <meta itemProp="position" content="0" />
          </li>

          {breadcrumbs.map((breadcrumb, i) => {
            var lasty = breadcrumbs.length;

            function lastOneTitle() {
              if (lasty === i + 1) {
                return pageTitle;
              } else {
                return prettify(breadcrumb);
              }
            }

            return (
              <li
                key={i}
                itemProp="itemListElement"
                itemScope={true}
                itemType="https://schema.org/ListItem"
              >
                <a
                  href={URLgen(breadcrumbs, i) + '/'}
                  itemScope={true}
                  itemType="https://schema.org/Thing"
                  itemProp="item"
                  itemID={breadcrumb}
                  title={lastOneTitle()}
                >
                  <span itemProp="name">{lastOneTitle()}</span>
                </a>
                <meta itemProp="position" content={i + 1} />
              </li>
            );
          })}
        </Breadcrumb>
      </Inner>
    </Wrapper>
  );
};

export default Breadcrumbs;
